import {
  ImageGalleyHome10,
  ImageGalleyHome11,
  ImageGalleyHome12,
  ImageGalleyHome13,
  ImageGalleyHome14,
  ImageGalleyHome3,
  ImageGalleyHome4,
  ImageGalleyHome5,
  ImageGalleyHome9,
} from "../images";

export const galleryData = [
  { src: ImageGalleyHome3, title: "Areas de Ceremonias" },
  { src: ImageGalleyHome4, title: "Piscinas de las Sedes" },
  { src: ImageGalleyHome5, title: "Piscinas de las Sedes" },
  { src: ImageGalleyHome9, title: "Piscinas de las Sedes" },
  { src: ImageGalleyHome10, title: "Areas Sociales" },
  { src: ImageGalleyHome11, title: "Areas de sociales" },
  { src: ImageGalleyHome12, title: "Areas de Eventos" },
  { src: ImageGalleyHome13, title: "oficinas sede chorrillos" },
  { src: ImageGalleyHome14, title: "oficinas sede chorrillos" },
];
